// See: https://github.com/CookPete/react-player/blob/master/src/demo/App.js

function AudioPlayer({
  m4a,
  mp3,
  index,
  loadNextPlayer,
  autoplayingPlayerIndex,
  autoplayNextTrack,
  stopAllAutoplaying,
  activePlayerIndex,
  setAsActivePlayer
}) {
  // State
  const [state, send] = useMachine(audioPlayerMachine)
  const [totalSeconds, setTotalSeconds] = useState(0)
  const [elapsedSeconds, setElapsedSeconds] = useState(0)

  // Refs
  const playerRef = useRef()

  // Computed properties
  const isActivePlayer = index === activePlayerIndex
  const isAutoplayingPlayer = index === autoplayingPlayerIndex

  // Effect #1: Autoplay player when play completes on previous track
  useEffect(() => {
    if (isAutoplayingPlayer) {
      send(`PLAY`)
    }
  }, [isAutoplayingPlayer, index, send])

  // Effect #2: Pause player if another one starts playing
  useEffect(() => {
    if (!isActivePlayer && !isAutoplayingPlayer && state.value === `playing`) {
      send(`PAUSE`)
    }
  }, [isActivePlayer, isAutoplayingPlayer, state.value, send, index])

  return (
    <>
      <FilePlayer
        ref={playerRef}
        width="0%"
        height="0%"
        url={[m4a, mp3]}
        onReady={() => {
          send(`READY`)
          loadNextPlayer()
        }}
        onDuration={duration => setTotalSeconds(duration)}
        playing={state.value === `playing`}
        onProgress={state => setElapsedSeconds(state.playedSeconds)}
        onError={e => console.log('onError', e)}
        onEnded={() => {
          send(`PAUSE`)
          autoplayNextTrack()
        }}
      />

      <Controls>
        {state.value === `loading` ? (
          <LoadingMessage>Loading...</LoadingMessage>
        ) : (
          <>
            <PlayPause
              onClick={() => {
                if (state.value === `paused`) {
                  send(`PLAY`)
                  setAsActivePlayer(index)
                  stopAllAutoplaying()
                }
                if (state.value === `playing`) {
                  send(`PAUSE`)
                  setAsActivePlayer(null)
                }
              }}
            >
              {state.value === `playing` ? (
                <>
                  <SrText>Pause</SrText>
                  <PauseSVG css={icon} aria-hidden="true" />
                </>
              ) : (
                <>
                  <SrText>Play</SrText>
                  <PlaySVG css={icon} aria-hidden="true" />
                </>
              )}
            </PlayPause>

            <Elapsed aria-label="Elapsed time" seconds={elapsedSeconds} />

            <label htmlFor={`progress-${index}`}>
              <SrText>Progress</SrText>
            </label>

            <Seek
              type="range"
              id={`progress-${index}`}
              min={0}
              max={totalSeconds}
              step={1}
              value={elapsedSeconds}
              onMouseDown={() => send(`START_SEEKING`)} // mouse
              onTouchStart={() => send(`START_SEEKING`)} // touchscreen
              onKeyDown={e => {
                if (e.keyCode > 36 && e.keyCode < 41) send(`START_SEEKING`)
              }} // keyboard
              onChange={e => {
                playerRef.current.seekTo(parseFloat(e.target.value))
                setElapsedSeconds(e.target.value)
              }}
              onMouseUp={() => send(`END_SEEKING`)} // mouse
              onTouchEnd={() => send(`END_SEEKING`)} // touchscreen
              onKeyUp={e => {
                if (e.keyCode > 36 && e.keyCode < 41) send(`END_SEEKING`)
              }} // keyboard
            />

            <Length aria-label="Total time" seconds={totalSeconds} />
          </>
        )}
      </Controls>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Controls = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--black);
  border-radius: var(--r1);
  padding: var(--s2);
  width: 14rem;
`

const LoadingMessage = styled.p`
  transform: translateY(2px);
  width: 14rem;
  font-style: italic;
  text-transform: uppercase;
`

const PlayPause = styled.button`
  flex: none;
  display: flex;
  align-items: center;
  margin-right: var(--s3);
  border: none;
  background-color: transparent;
  text-align: left;
`

const Elapsed = styled(Duration)`
  flex: none;
  transform: translateY(2px);
  width: 2.5rem;
`

const Length = styled(Duration)`
  flex: none;
  transform: translateY(2px);
  text-align: right;
  width: 2.5rem;
`

const Seek = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  flex: auto;
  margin-left: var(--s2);
  margin-right: var(--s1);
  border-radius: var(--r6);
  background-color: var(--black);
  width: 100%; /* Required for Firefox. */
  height: 0.2rem;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 100%;
    border: none;
    background-color: var(--black);
    width: var(--s3);
    height: var(--s3);
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    border-radius: 100%;
    border: none;
    background-color: var(--black);
    width: var(--s3);
    height: var(--s3);
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    border-radius: 100%;
    border: none;
    background-color: var(--black);
    width: var(--s3);
    height: var(--s3);
  }
`

///////////////////////////////////////////////////////////////////////////////////

function Duration({ seconds, ...props }) {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} {...props}>
      {format(seconds)}
    </time>
  )
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ('0' + string).slice(-2)
}

///////////////////////////////////////////////////////////////////////////////////

import React, { useRef, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import FilePlayer from 'react-player/lib/players/FilePlayer'

import { SrText } from './elements'
import useMachine from '../logic/useMachine'
import { audioPlayerMachine } from '../logic/audioPlayer'
import { ReactComponent as PlaySVG } from '../svg/play-solid.svg'
import { ReactComponent as PauseSVG } from '../svg/pause-solid.svg'
import { icon } from '../styles'

export default AudioPlayer
