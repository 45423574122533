// State chart for a custom audio player

export const audioPlayerMachine = Machine({
  id: 'audioPlayerMachine',

  initial: 'loading',

  states: {
    loading: {
      on: { READY: 'paused' }
    },

    paused: {
      on: {
        START_SEEKING: 'seekingWhilePaused',
        PLAY: 'playing'
      }
    },

    seekingWhilePaused: {
      on: {
        END_SEEKING: 'paused'
      }
    },

    playing: {
      on: {
        START_SEEKING: 'seekingWhilePlaying',
        PAUSE: 'paused'
      }
    },

    seekingWhilePlaying: {
      on: {
        END_SEEKING: 'playing'
      }
    }
  }
})

///////////////////////////////////////////////////////////////////////////////////

import { Machine } from 'xstate'
