/*
 *
 * This template is used to lay out the individual film pages that are
 * generated programatically by gatsby-node using the data in films.yml
 *
 */

function FilmPage({ data, pageContext: { prev, next } }) {
  const film = data.allFilmsYaml.edges[0].node
  const { title, siteUrl } = useSiteMetadata()

  return (
    <Base>
      <Metadata
        page={{
          title: `${film.title.replace(`&nbsp;`, ` `)} | ${title}`,
          description: `Selections from the music composed by Lesley Barber for the film, "${film.title.replace(
            `&nbsp;`,
            ` `
          )}".`,
          url: `${siteUrl}/${film.slug}/`
        }}
      />

      <Header>
        <Photo
          fluid={{
            ...film.pageImage.file.childImageSharp.fluid,
            aspectRatio: 16 / 9
          }}
          alt={film.pageImage.alt}
          loading="eager"
          imgStyle={{ objectPosition: film.pageImage.objPosition }}
        />
        <FilmTitle dangerouslySetInnerHTML={{ __html: film.title }} />
      </Header>

      <main id="main-content">
        <Section>
          <h2>
            <SrText>Music by Lesley Barber</SrText>
          </h2>

          {film.tracks ? (
            <Tracks tracks={film.tracks} />
          ) : (
            <ComingSoon>Coming soon</ComingSoon>
          )}

          {film.soundtracks && (
            <Soundtracks>
              {film.soundtracks.length > 1 ? (
                <ul>
                  {film.soundtracks.map(soundtrack => (
                    <Soundtrack key={soundtrack.href}>
                      <Link href={soundtrack.href}>{soundtrack.text}</Link>
                    </Soundtrack>
                  ))}
                </ul>
              ) : (
                <Link href={film.soundtracks[0].href}>
                  {film.soundtracks[0].text}
                </Link>
              )}
            </Soundtracks>
          )}

          {(prev || next) && (
            <PrevNext>
              {prev && (
                <PrevLink href={`/${prev.slug}/`}>
                  ← <span dangerouslySetInnerHTML={{ __html: prev.title }} />
                </PrevLink>
              )}
              {next && (
                <NextLink href={`/${next.slug}/`}>
                  <span dangerouslySetInnerHTML={{ __html: next.title }} /> →
                </NextLink>
              )}
            </PrevNext>
          )}
        </Section>
      </main>
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Header = styled.header`
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--s8);
  max-width: 28rem;
  text-align: center;
`

const Section = styled.section`
  margin-left: auto;
  margin-right: auto;
  padding-bottom: var(--s10);
  max-width: 36rem;
  text-align: center;
`

const Photo = styled(Image)`
  margin-top: var(--s5);
`

const FilmTitle = styled.h1`
  padding-top: var(--s5);
  font-size: var(--f6);
  font-weight: 400;
  text-transform: uppercase;
`

const ComingSoon = styled.p`
  margin-top: var(--s8);
  font-size: var(--f4);
  font-weight: 700;
  text-transform: uppercase;
`

const Soundtracks = styled.nav`
  margin-top: var(--s8);
`

const Soundtrack = styled.li`
  margin-top: var(--s3);
`

const PrevNext = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: var(--s8);
  font-weight: 700;
`

const PrevLink = styled(Link)`
  margin-top: var(--s6);
  margin-right: auto;
`

const NextLink = styled(Link)`
  margin-top: var(--s6);
  margin-left: auto;
`

///////////////////////////////////////////////////////////////////////////////////

function Tracks({ tracks }) {
  // Load one player at a time, sequentially
  const [loadingPlayerIndex, setLoadingPlayerIndex] = useState(0)
  const [allPlayersLoaded, setAllPlayersLoaded] = useState(false)

  // If autoplay is allowed by browser, autoplay next track when current track ends
  const [isAutoplayAllowed, setIsAutoplayAllowed] = useState(false)
  const [autoplayingPlayerIndex, setAutoplayingPlayerIndex] = useState(null)

  // Play only one player at a time (pause others when play starts)
  const [activePlayerIndex, setActivePlayerIndex] = useState(null)

  // Effect #1: Check if autoplay is allowed
  // In Chrome, this starts 'false' and becomes 'true' after the first interaction
  // In mobile Chrome and Safari, this is always false
  React.useEffect(() => {
    if (!isAutoplayAllowed) {
      canAutoplay.audio().then(({ result }) => {
        if (result === true) setIsAutoplayAllowed(true)
      })
    }
  })

  return (
    <TrackList>
      {tracks.map((track, i) => (
        <Track key={i}>
          <Title>{track.title}</Title>
          {i <= loadingPlayerIndex || allPlayersLoaded ? (
            <AudioPlayer
              m4a={track.m4a.publicURL}
              mp3={track.mp3.publicURL}
              index={i}
              loadNextPlayer={() => {
                if (i + 1 === tracks.length) setAllPlayersLoaded(true)
                if (!allPlayersLoaded) setLoadingPlayerIndex(i + 1)
              }}
              autoplayingPlayerIndex={autoplayingPlayerIndex}
              autoplayNextTrack={() => {
                if (isAutoplayAllowed) {
                  setAutoplayingPlayerIndex(i < tracks.length - 1 ? i + 1 : 0)
                  setActivePlayerIndex(i < tracks.length - 1 ? i + 1 : 0)
                }
              }}
              stopAllAutoplaying={() => setAutoplayingPlayerIndex(null)}
              activePlayerIndex={activePlayerIndex}
              setAsActivePlayer={setActivePlayerIndex}
            />
          ) : (
            <Controls>
              <LoadingMessage>Loading...</LoadingMessage>
            </Controls>
          )}
        </Track>
      ))}
    </TrackList>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const TrackList = styled.ul`
  padding-top: var(--s4);
`

const Track = styled.li`
  padding-top: var(--s7);
`

const Title = styled.span`
  display: block;
  padding-bottom: var(--s2);
  font-size: 1.05rem;
  font-weight: 400;
  font-style: italic;
`

const Controls = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--black);
  border-radius: var(--r1);
  padding: var(--s2);
  width: 14rem;
`

const LoadingMessage = styled.p`
  transform: translateY(2px);
  width: 14rem;
  font-style: italic;
  text-transform: uppercase;
`

///////////////////////////////////////////////////////////////////////////////////

// NOTE: leave this query here (can't extract to usStaticQuery if using variables)
// See: https://github.com/gatsbyjs/gatsby/issues/9047#issuecomment-471104084
export const query = graphql`
  query($slug: String!) {
    allFilmsYaml(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          slug

          pageImage {
            file {
              childImageSharp {
                fluid(maxWidth: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            objPosition
          }

          tracks {
            title
            m4a {
              publicURL
            }
            mp3 {
              publicURL
            }
          }

          soundtracks {
            text
            href
          }
        }
      }
    }
  }
`

///////////////////////////////////////////////////////////////////////////////////

import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'
import canAutoplay from 'can-autoplay'

import Base from './Base'
import Metadata from './Metadata'
import AudioPlayer from './AudioPlayer'
import { Link, SrText } from './elements'
import useSiteMetadata from '../data/useSiteMetadata'

export default FilmPage
